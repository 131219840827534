<template>
	<div>
		<div v-if="isLoading" id="myModal" class="modal">
			<div class="modal-content">
				<p>Please wait. The server is preparing your files.</p>
			</div>
		</div>

		<div class="container">
			<div class="title">Your files</div>
			<div v-if="list.files.length > 1">
				<button v-on:click="zip" class="button">Download all</button>
			</div>
			<div v-if=list.files class="table">
				<div class="table-header">
					<div class="table-header-filename">Filename</div>
					<div class="table-header-size">Size</div>
				</div>
				<div class="table-body">
					<div class="table-body-row" v-for="item in list.files">
						<div class="table-body-filename"><a v-bind:href="item.url" target="_blank">{{item.name}}</a></div>
						<div class="table-body-size">{{item.size | prettyBytes}}</div>
					</div>
				</div>
				<div class="table-footer">
					<div>{{list.totalSize | prettyBytes}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

const jszip = require('jszip')
const jsziputils = require('jszip-utils')
import FileList from '@/services/filelist.js'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import FileSaver from 'file-saver'

export default {
    name: 'Upload',
    data() {
        return {
            fileList: new FileList(),
            id: this.$route.params.id,
            list: {
                numFiles: 0,
                totalSize: 0,
                files: ''
            },
            isLoading: false
        }
    },
    methods: {
        blah() {
            this.isLoading = false;
        },
        home() {
            this.$router.push({name: 'Shr'})
        },
        zip(){
            let zip = new JSZip()
            let count = 0
            const zipFileName = "archive.zip"
            const num = this.list.files.length
            this.isLoading = true;
            const self = this;
            this.list.files.forEach((file) => {
                let filename = file.name
                JSZipUtils.getBinaryContent(file.url, function (err, data) {
                    if(err) {
                        self.isLoading = false;
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, {binary:true});
                    count++;
                    if (count == num) {
                        zip.generateAsync({type: "blob"})
                            .then((content) => {
                                self.isLoading = false;
                                FileSaver.saveAs(content, zipFileName)
                            })
                    }
                })
            })
        }
    },
    mounted() {
        this.fileList.getList(this.id, (list) => {
            this.list = list
        })
    }

}
</script>

<style scoped>

.table {
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-areas:
        "table-header"
        "table-body"
        "table-footer";
}

.table-header {
    font-size: 1rem;
    font-weight: 700;
    grid-area: table-header;
    display: grid;
    grid-template-columns: 6fr 2fr;
    padding: 1rem;
}

.table-header-size {
    justify-self: end;
}

.table-header-filename {
    justify-self: start;
}

.table-body {
    grid-area: table-body;
    line-height: 2rem;
}

.table-body-row {
    display:grid;
    grid-template-columns: 6fr 2fr;
    border-bottom: 1px solid var(--second);
}

.table-body-row:hover {
    background: var(--second)
}

.table-body-size {
    justify-self: end;
    padding-right: 1rem;
    font-weight: 300;
}

.table-body-filename {
    justify-self: start;
    padding-left:1rem;
}

.table-footer {
    grid-area: table-footer;
    justify-self: end;
    padding: 1rem;
    font-weight: 700;
}

.modal {
	display: block; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0,0,0); /* Fallback color */
	background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
	background-color: #fefefe;
	margin: 15% auto; /* 15% from the top and centered */
	padding: 2rem;
	border: 1px solid #888;
	width: 80%; /* Could be more or less, depending on screen size */
    max-width: var(--max-width);
}


</style>
