import axios from 'axios';

export default class FileList {

    getList(id, cb)
    {
        axios.post('/list/'+id)
        .then(response => {
            let files = []
            let numFiles = 0
            let totalSize = 0
            for (let file of response['data']) {
                let record = {
                    name: file.name,
                    url: './' + id +'/' +file.name,
                    size: file.size
                }
                numFiles++
                totalSize += record.size
                files.push(record)
            }
            const rtn = {
                numFiles: numFiles,
                totalSize: totalSize,
                files: files
            }
            cb(rtn)
        })
        .catch(e => {
            const rtn = {
                numFiles: 0,
                totalSize: 0,
                files: ''
            }
            cb(rtn)
        });
    }
}
